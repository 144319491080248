<script>
    import { setContext, onMount } from 'svelte';
    import SettingsNav from '../../Pages/settings/SettingsNav.svelte';
    import SettingsMobileNav from './SettingsMobileNav.svelte';
    import SettingsHeader from '../../Pages/settings/SettingsHeader.svelte';
    import SettingsProfile from '../../Pages/settings/SettingsProfile.svelte';
    import SettingsSecurity from './SettingsSecurity.svelte';
    import SettingsConnection from './SettingsConnection.svelte';
    import SettingsRates from './SettingsRates.svelte';
    import SettingsSubscription from './SettingsSubscription.svelte';
    import SettingsReferrals from './SettingsReferrals.svelte';
    import SettingsNotifications from './SettingsNotifications.svelte';
    import SettingsPrivacy from './SettingsPrivacy.svelte';
    import SettingsVerify from './SettingsVerify.svelte';
    import SettingsVipVerify from './SettingsVipVerify.svelte';

    // props from settings controller
    export let availableSettings;
    export let currentSettingTab;
    export let activeSettingsTab;
    export let additionalAssets;
    export let authUser;
    export let data;
    export let cookie_app_theme;
    export let default_user_theme;
    export let authUserVerification;

    export let errors = [];

    // Reactive statement to update the context whenever props change
    $: {
        const settingsContext = {
            data,
            availableSettings,
            currentSettingTab,
            activeSettingsTab,
            additionalAssets,
            authUser,
            cookie_app_theme,
            default_user_theme,
            authUserVerification,
        };
        setContext('settingsContext', settingsContext);
    }

    // done dialog jumping around jumping happens when the initial place allocated is smaller than the one covered by the embed
    // done  width on desktop should mostly fill the explore feed width (90% of it maybe)
    // done  and add a bottom bar with creator profile icon, username and name, as well as a -> type="ghost" link to the full post
    // regarding settings: the datepicker for the date of birth needs to be locked to historical and advanced calendar
    // done  use e.g. a temporarily lower opacity, or a white overlay with temporary opacity
    // done is that we need a mobile compatible navbar
    // vertically centered texts and bigger size
</script>

<div class="">
    <div class="row">
        <div class="col-12 col-md-4 col-lg-3 settings-menu mb-3 pr-0">
            <div class="settings-menu-wrapper">
                <div class="d-none d-md-block">
                    <svelte:component this="{SettingsHeader}" type="generic" />
                </div>
                <hr class="m-0" />
                <div class="d-none d-md-none mt-3">
                    <svelte:component this="{SettingsHeader}" type="" />
                </div>
                <div class="d-none d-md-block">
                    <svelte:component this="{SettingsNav}" />
                </div>
            </div>
        </div>
        <div
            class="col-md-8 col-lg-9 mb-lg-0 min-vh-100 border-left border-right settings-content mt-md-0 pl-md-0 pr-md-0 mb-5 mt-1"
        >
            <div class="d-md-flex justify-content-between ml-3">
                <div>
                    <h5 class="mt-md-3 mb-0 mt-0 font-bold">
                        {activeSettingsTab.charAt(0).toUpperCase() + activeSettingsTab.slice(1)}
                    </h5>
                    <h6 class="mb-2 mt-2 text-muted">{currentSettingTab['heading']}</h6>
                </div>
            </div>
            <hr class="d-md-block m-0 mt-2" />
            <!-- settings mobile nav -->
            <SettingsMobileNav />
            <div
                class="px-md-3 px-4 pt-3 {(!authUser.email_verified_at || !authUser.birthdate) &&
                activeSettingsTab === 'verify'
                    ? 'verfyuiblur'
                    : ''}"
            >
                {#if activeSettingsTab === 'profile'}
                    <svelte:component this="{SettingsProfile}" />
                {:else if activeSettingsTab === 'security'}
                    <svelte:component this="{SettingsSecurity}" />
                {:else if activeSettingsTab === 'connections'}
                    <svelte:component this="{SettingsConnection}" />
                {:else if activeSettingsTab === 'rates'}
                    <svelte:component this="{SettingsRates}" />
                {:else if activeSettingsTab === 'subscriptions'}
                    <svelte:component this="{SettingsSubscription}" {data} />
                {:else if activeSettingsTab === 'referrals'}
                    <svelte:component this="{SettingsReferrals}" />
                {:else if activeSettingsTab === 'notifications'}
                    <svelte:component this="{SettingsNotifications}" />
                {:else if activeSettingsTab === 'privacy'}
                    <svelte:component this="{SettingsPrivacy}" />
                {:else if activeSettingsTab === 'verify'}
                    <svelte:component this="{SettingsVerify}" />
                {:else if activeSettingsTab === 'vip-verification'}
                    <svelte:component this="{SettingsVipVerify}" />
                {/if}
            </div>
        </div>
    </div>
    <slot></slot>
</div>

<!-- Use the errors prop somewhere in your component -->
{#if errors.length}
    <ul>
        {#each errors as error}
            <li>{error}</li>
        {/each}
    </ul>
{/if}
